import React, { Component } from 'react';
import { Line } from 'react-chartjs-2'
import moment from 'moment';
import Utils from '../../utils/Utils';
import { CHART_DATE_DEFAULT_FORMAT, CHART_DATE_FULL_FORMAT } from '../../utils/Consts';

class VisitorsLineGraph extends Component {

    convertDataForGraph = (chartData, startTime, endTime) => {
        const dates = Utils.generateDateList(startTime,endTime);
        const fullDateStrs = Utils.generateDateList(startTime, endTime, CHART_DATE_FULL_FORMAT);
        let numVisitors = Array(dates.length).fill(0);
        chartData.forEach(cd => {
            const dateStr = moment.utc(cd.timestamp).format(CHART_DATE_DEFAULT_FORMAT);
            const dateIndex = dates.indexOf(dateStr);
            numVisitors[dateIndex] = cd.visitors;
        });

        const data = {
            labels: dates,
            fullDateStrs: fullDateStrs,
            datasets: [{
                label: "Visitors/Day",
                backgroundColor: '#c1bae3',
                pointHitRadius: 20,
                data: numVisitors
            }],
        }

        const options = {
            legend: {
                position: 'bottom'   
            },
            scales: {
                yAxes: [{
                    position: 'right',
                    ticks: {
                        // Include a dollar sign in the ticks
                        callback: function(value, index, values) {
                            return Utils.formatIntNumber(value);
                        }
                    },
                },],
            },
            tooltips: {
                mode: 'label',
                callbacks: {
                    title: function(tooltipItem, data) {
                        var title = data.fullDateStrs[tooltipItem[0].index];
                        return title;
                    },
                    label: function(tooltipItem, data) {
                        return Utils.formatIntNumber(tooltipItem.yLabel);
                    },
                }
            }
        }        

        return { data, options };
    }
    
    
    render() {
        const { chartData, startTime, endTime } = this.props || {};
        if (!chartData || chartData.length === 0) {
            return null;
        }

        const { data, options } = this.convertDataForGraph(chartData, startTime, endTime);

        return (
            <div>
                <h2>Total Visitors</h2>
                <Line 
                    data = { data } 
                    options = { options }
                />
            </div>
        );
    }
}

export default VisitorsLineGraph;
