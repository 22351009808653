import React, { Component } from 'react';
import SummaryView from '../components/summary/SummaryView';
import { VIEW_MODE } from '../utils/Consts';

/**
 * Summary Page for normal merchant user
 */
class Summary extends Component {
  render() {
    return (
      <SummaryView mode={VIEW_MODE.MERCHANT} />
    );
  }
}

export default Summary;
