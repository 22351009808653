import React, { Component } from 'react';
import {
  Chart, 
  Axis, 
  Tooltip, 
  Geom,
  Legend,
} from "bizcharts";
import moment from 'moment';

import Utils from '../../utils/Utils';
import { CHART_DATE_DEFAULT_FORMAT, CHART_DATE_FULL_FORMAT } from '../../utils/Consts';

class OrderPeriodChart extends Component {
  convertDataForChart =(value, parameters, startTime, endTime)=> {
    let defaultKeyValues = {}
    parameters.forEach(p => {
      defaultKeyValues[p.key] = 0;
    });

    let indexMap = {};
    let datePeriods = [];
    const dateStrList = Utils.generateDateList(startTime, endTime, CHART_DATE_DEFAULT_FORMAT);
    const fullDateStrList = Utils.generateDateList(startTime, endTime, CHART_DATE_FULL_FORMAT);
    for (var index = 0; index < dateStrList.length; index++) {
      indexMap[dateStrList[index]] = index;
      let row = JSON.parse(JSON.stringify(defaultKeyValues))
      row['date'] = dateStrList[index];
      row['fullDateStr'] = fullDateStrList[index];
      datePeriods.push(row);
    }

    for (var valueIndex = 0; valueIndex < value.length; valueIndex++) {
      const item = value[valueIndex];
      const dateStr = moment.utc(item.timestamp).format(CHART_DATE_DEFAULT_FORMAT);
      const dateIndex = indexMap[dateStr];
      for (var parameterIndex = 0; parameterIndex < parameters.length; parameterIndex++) {
        const para = parameters[parameterIndex];
        if (item.hasOwnProperty(para.key) && datePeriods[dateIndex]) {
          datePeriods[dateIndex][para.key] = item[para.key];
        }
      }
    }

    let data = [];
    for (var i = 0; i < datePeriods.length; i++) {
      const datePeriod = datePeriods[i];
      const dateStr = datePeriod.date;
      const fullDateStr = datePeriod.fullDateStr;
      for (var dpi = 0; dpi < parameters.length; dpi++) {
        const para = parameters[dpi];
        data.push({
          date: dateStr,
          fullDateStr: fullDateStr,
          // convert to day 
          value: parseFloat((datePeriod[para.key] / 86400000).toFixed(1)),
          type: para.label,
        });
      }
    }

    return data;
  }

  render() {
    const { value, startTime, endTime, title, parameters } = this.props || {};
    if (!value) {
      // if data is not ready, show nothing
      return null;
    }

    if (Object.keys(value).length === 0) {
      // return null if empty
      return null;
    }
    
    const data = this.convertDataForChart(value, parameters, startTime, endTime);
    const cols = {
      date: {
        tickCount: 10,
      },
    };
    
    return (
      <div>
        <h3>{title}</h3>
        <Chart height={400} data={data} scale={cols} forceFit>
          <Axis name="date" />
          <Axis name="value" />
          <Legend />
          <Tooltip />
          <Geom type="intervalStack" position="date*value" 
            color="type" 
            adjust={[
              {
                type: "dodge",
                marginRatio: 1 / 32
              }
            ]}
            tooltip={['type*value*fullDateStr', (type, value, fullDateStr)=>{
              return {
                name: type,
                value: Utils.formatFloatNumber(value) + ' day(s)',
                title: fullDateStr,
              }
            }]}
          />
        </Chart>
      </div>
    );
  }
}

export default OrderPeriodChart;
