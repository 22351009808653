import React, { Component } from 'react';
import CustomersView from '../components/customers/CustomersView'
import { VIEW_MODE } from '../utils/Consts';
/**
 * Customers Page for Admin user
 */
class AdminCustomers extends Component {
  render() {
    return (
      <CustomersView mode={VIEW_MODE.ADMIN} />
    );
  }
}

export default AdminCustomers;
