import React, { Component } from 'react';
import { Table } from 'antd';
import Utils from '../../utils/Utils';

class SummaryProductsTable extends Component {

  convertDataForTable = (data, productsMap, merchantId) => {
    const columns = [{
      title: 'ID',
      dataIndex: 'productId',
      key: 'productId',
      width: 80,
    }, {
      title: 'Name',
      dataIndex: 'productName',
      key: 'productName',
      width: 300,
    }, {
      title: 'Brand',
      dataIndex: 'brand',
      key: 'brand',
      width: 100,
    }, {
      title: 'Price',
      dataIndex: 'price',
      key: 'price',
      width: 80,
    }, {
      title: 'Total Units',
      dataIndex: 'totalUnits',
      key: 'totalUnits',
      width: 100,
    }, {
      title: 'Total Amount',
      dataIndex: 'totalSales',
      key: 'totalSales',
      width: 100,
    }];
    
    let dataSource = [];
    if (!data) {
      return { dataSource, columns };
    }

    dataSource = data.map((d) => {
      let brand = '';
      let productName = d.productName;
      if (productsMap[d.productId]) {
        brand = productsMap[d.productId].brand;
        productName = productsMap[d.productId].name;
      }
      let productid = "";
      if ((merchantId === 'ebcfa01b-b4f8-474f-8374-180f390a5b05') && (d.productId.length > 32)) {
        let s = atob(d.productId.split('_').slice(-1)[0]);
        productid = s.substr(s.indexOf('t/') + 2,s.length);
      }
      else {
        productid = d.productId.split('_').slice(-1)[0];
      }

      return {
        productId: productid,
        productName: productName,
        brand: brand,
        totalUnits: Utils.formatIntNumber(d.totalUnits),
        totalSales: Utils.formatCurrencyNumber(d.totalSalesCents / 100.0),
        price: Utils.formatCurrencyNumber(d.totalSalesCents / d.totalUnits / 100.0),
        key: d.productId,
      }
    });

    return { dataSource, columns };
  }

  render() {
    const { data, productsMap, merchantId } = this.props || {};
    if (Object.keys(data).length === 0) {
      // return null if empty
      return null;
    }
    const { dataSource, columns } = this.convertDataForTable(data, productsMap, merchantId);
    return (
      <React.Fragment>
        <h4> TOP-SALE PRODUCTS </h4>
        <Table dataSource={dataSource}
          columns={columns} 
          pagination={false}
        />
      </React.Fragment>
    )
  }
}

export default SummaryProductsTable;
