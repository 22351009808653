import React, { Component } from 'react';
import { Layout } from 'antd';
import './BasicLayout.css';
import LeftSider from './LeftSider';
import AppFooter from './AppFooter';
import AppHeader from './AppHeader';


class BasicLayout extends Component {
  render() {
    const { content } = this.props || {};
    return (
      <Layout style={{minHeight: '100vh'}}>
        <LeftSider />
        <Layout>
          <AppHeader />
          <Layout.Content
            className="layout-content"
            breakpoint="lg">
            { content }
          </Layout.Content>
          <AppFooter />
        </Layout>
      </Layout>
    );
  }
}

export default BasicLayout;
