import moment from 'moment';
import numeral from 'numeral';
import { CHART_DATE_DEFAULT_FORMAT } from './Consts';

export default class Utils {
  /**
   * Get the day list, since the response data from backend may skip dates that don't have any orders
   * and front-end needs to fill those date with zeros, for better user experience
   */
  static generateDateList(startTime, endTime, strFormat=CHART_DATE_DEFAULT_FORMAT) {
    let dateList = [];
    // time diff in milliseconds
    let mom = moment(startTime)
    while (mom.valueOf() <= endTime) {
      dateList.push(mom.format(strFormat));
      mom = mom.add(1, 'day'); 
    }
    return dateList;
  }

  static formatIntNumber(number) {
    return numeral(number).format('0,0')
  }

  static formatFloatNumber(number) {
    return numeral(number).format('0,0.0')
  }

  static formatCurrencyNumber(number) {
    return numeral(number).format('$0,0.00')
  }

  static formatPercentageNumber(number) {
    return numeral(number).format('0.00%');
  }
}