import React, { Component } from 'react';
import { Bar } from 'react-chartjs-2';
import moment from 'moment';
import Utils from '../../utils/Utils';
import { CHART_DATE_DEFAULT_FORMAT, CHART_DATE_FULL_FORMAT } from '../../utils/Consts';

class UserCompBarGraph extends Component {

    convertDataForChart =(chartData, startTime, endTime)=> {
        const dates = Utils.generateDateList(startTime, endTime);
        const fullDateStrs = Utils.generateDateList(startTime, endTime, CHART_DATE_FULL_FORMAT);
        let visitors = Array(dates.length).fill(0);
        let addedCart = Array(dates.length).fill(0);
        let newBuyers = Array(dates.length).fill(0);
        let repurchaseBuyers = Array(dates.length).fill(0);
        chartData.forEach(cd => {
          // our data is in UTC time
            const dateStr = moment.utc(cd.timestamp).format(CHART_DATE_DEFAULT_FORMAT);
            const dateIndex = dates.indexOf(dateStr);
            visitors[dateIndex] = cd.visitors;
            addedCart[dateIndex] = cd.addedToCartUsers;
            newBuyers[dateIndex] = cd.newBuyers;
            repurchaseBuyers[dateIndex] = cd.repurchaseBuyers;
        });

        let data ={ 
            labels: dates,
            fullDateStrs: fullDateStrs,
            datasets:[
                {
                    label: "Visitors",
                    data: visitors,
                    backgroundColor:'#ff8595'
                },
                {
                    label: "Added_To_Cart Users",
                    data: addedCart,
                    backgroundColor: '#9ae4da'
                },
                {
                    label: "New Buyers",
                    data: newBuyers,
                    backgroundColor: '#c1bae3'
                },
                {
                    label: "Repurchase Buyers",
                    data: repurchaseBuyers,
                    backgroundColor: '#d7f7b2'
                }
            ],
        }

        let options = {
            scales: {
                xAxes: [{
                    stacked: true
                }],
                yAxes: [{
                    stacked: true,
                    position: 'right',
                    ticks: {
                        // Include a dollar sign in the ticks
                        callback: function(value, index, values) {
                            return Utils.formatIntNumber(value);
                        }
                    },
                }]
            },
            legend: {
                position: 'bottom'   
            },
            tooltips: {
                mode: 'label',
                callbacks: {
                    title: function(tooltipItem, data) {
                        var title = data.fullDateStrs[tooltipItem[0].index];
                        return title;
                    },
                    label: function(tooltipItem, data) {
                        return Utils.formatIntNumber(tooltipItem.yLabel);
                    },
                }
            }
        }

        return { data, options };
    }
    
    render () {
        const { chartData, startTime, endTime} = this.props || {};
        
        if (!chartData || chartData.length === 0) {
            return null;
        }

        const { data, options } = this.convertDataForChart(chartData, startTime, endTime);
        
    
        return (
            <div>
                <Bar 
                    data={ data } 
                    options= { options }
                />
            </div>
        )
    }
}

export default UserCompBarGraph;
