import React, { Component } from 'react';
import SummaryView from '../components/summary/SummaryView';
import { VIEW_MODE } from '../utils/Consts';

class AdminSummary extends Component {
  render() {
    return (
      <SummaryView mode={VIEW_MODE.ADMIN} />
    );
  }
}

export default AdminSummary;