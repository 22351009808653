import React, { Component } from 'react';
import { Select } from 'antd';
import MerchantInfoManager from '../../utils/MerchantInfoManager';

class MerchantSelector extends Component {
  render() {
    const { onChange, onBlur, onFocus, value } = this.props || {};
    const merchants = MerchantInfoManager.merchantListForSelector();
    const options = merchants.map(m=> {
      return <Select.Option value={m.id} key={m.id}>{m.name}</Select.Option>
    })

    return (
      <Select
        showSearch
        style={{ width: '100%' }}
        placeholder="Select a merchant"
        optionFilterProp="children"
        onChange={onChange}
        onFocus={onBlur}
        onBlur={onFocus}
        value={value}
        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
      >
        {options}
      </Select>
    );
  }
}

export default MerchantSelector;
