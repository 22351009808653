import React, { Component } from 'react';
import {
  Chart, 
  Axis, 
  Tooltip, 
  Geom,
} from "bizcharts";
import moment from 'moment';

import Utils from '../../utils/Utils';
import { CHART_DATE_DEFAULT_FORMAT, CHART_DATE_FULL_FORMAT } from '../../utils/Consts';

class EmailRateChart extends Component {
  
  convertDataForChart =(rates, startTime, endTime)=> {
    const dateStrList = Utils.generateDateList(startTime, endTime, CHART_DATE_DEFAULT_FORMAT);
    const fullDateStrList = Utils.generateDateList(startTime, endTime, CHART_DATE_FULL_FORMAT);
    let indexMap = {};
    let data = [];
    for (var index = 0; index < dateStrList.length; index++) {
      // create a str to index map, for faster insert
      indexMap[dateStrList[index]] = index;
      // dafault value is 0
      data.push({
        date: dateStrList[index],
        rate: 0,
        fullDateStr: fullDateStrList[index],
      });
    }

    rates.forEach((rate)=>{
      const dateStr = moment.utc(rate.timestamp).format(CHART_DATE_DEFAULT_FORMAT);
      const dateIndex = indexMap[dateStr];
      if (dateIndex < data.length) {
        data[dateIndex].rate = rate.noEmailRate || 0;
      }
    });

    return data;
  }

  render() {
    const { value, startTime, endTime } = this.props || {};
    if (!value) {
      // if data is not ready, show nothing
      return null;
    }

    if (Object.keys(value).length === 0) {
      // return null if empty
      return null;
    }
    
    const data = this.convertDataForChart(value, startTime, endTime);

    const cols = {
      rate: {
        min: 0,
      },
      date: {
        range: [0, 1],
        tickCount: 10,
      },
    };

    const rateAxixLabel = {
      formatter(text, item, index) {
        let p = (parseFloat(text) * 100).toFixed(1)
        return `${p}%`;
      },
    }
    return (
      <div>
        <h3>NO ORDER CONFIRMATION EMAIL RATE</h3>
        <Chart height={400} data={data} scale={cols} forceFit>
          <Axis name="date" />
          <Axis name="rate" label={rateAxixLabel} />
          <Tooltip />
          <Geom type="line" position="date*rate" size={2} 
            tooltip={['date*rate*fullDateStr', (date, rate, fullDateStr)=>{
              return {
                name:'rate:',
                value: Utils.formatPercentageNumber(rate),
                title: fullDateStr,
              }
            }]} 
          />
          <Geom
            type="point"
            position="date*rate"
            size={4}
            shape={"circle"}
            style={{
              stroke: "#fff",
              lineWidth: 1
            }}
            tooltip={['rate*fullDateStr', (rate, fullDateStr)=>{
              return {
                name:'rate:',
                value: Utils.formatPercentageNumber(rate),
                title: fullDateStr,
              }
            }]
          }
          />
        </Chart>
      </div>
    );
  }
}

export default EmailRateChart;
