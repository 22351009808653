import React, { Component } from 'react';
import {
  Chart,
  Geom,
  Axis,
  Tooltip,
  Legend,
} from "bizcharts";
import moment from 'moment';
import DataSet from "@antv/data-set";
import { 
  Tag,
  Divider,
  Tooltip as AntTooltip,
} from 'antd';
import Utils from '../../utils/Utils';
import { CHART_DATE_DEFAULT_FORMAT, CHART_DATE_FULL_FORMAT } from '../../utils/Consts';


const DEFINITIONS = [
  {
    label: 'Item Unavailable',
    description: 'failed order due to the item being unavailable (size, color, inventory)',
  },
  {
    label: 'Gift Out Of Stock',
    description: 'failed order due to the gift included in order being out of stock',
  },
  {
    label: 'Promotion Code Error',
    description: 'failed order due to a promotion code being either invalid or expired',
  },
  {
    label: 'Max Price Exceeded',
    description: 'failed order due to the price of the product being higher than what was said on Beyond',
  },
  {
    label: 'Max Quantity Exceeded',
    description: 'failed order due to the quantity of items being requested is unavailable by the merchant',
  },
  {
    label: 'User Cancel',
    description: 'failed order due to a user canceling an order or requesting a return',
  },
  {
    label: 'Other',
    description: 'failed order due to reasons other than above',
  },
];

class FailedOrderChart extends Component {

  convertDataForChart =(value, startTime, endTime)=> {
    const dateStrList = Utils.generateDateList(startTime, endTime, CHART_DATE_DEFAULT_FORMAT);
    const fullDateStrList = Utils.generateDateList(startTime, endTime, CHART_DATE_FULL_FORMAT);
    const breakDownKeys = ['unavailable', 'giftOutOfStock', 'maxPriceExceeded', 'maxQuantityExceeded', 'promotionError', 'userCancel', 'unknown'];
    const breakDownTypes = {
      unavailable: 'Item Unavailable',
      giftOutOfStock: 'Gift Out Of Stock', 
      maxPriceExceeded: 'Max Price Exceeded', 
      maxQuantityExceeded: 'Max Quantity Exceeded', 
      promotionError: 'Promotion Code Error', 
      userCancel: 'User Cancel', 
      unknown: 'Other',
    }
    let indexMap = {};
    let dateBreakdown = [];
    
    let defaultKeyValues = {}
    breakDownKeys.forEach(key => {
      defaultKeyValues[key] = 0;
    });

    for (var index = 0; index < dateStrList.length; index++) {
      // create a str to index map, for faster insert
      indexMap[dateStrList[index]] = index;
      let row = JSON.parse(JSON.stringify(defaultKeyValues))
      row['date'] = dateStrList[index];
      row['fullDateStr'] = fullDateStrList[index];
      dateBreakdown.push(row);
    }
    
    for (var valueIndex = 0; valueIndex < value.length; valueIndex++) {
      const item = value[valueIndex];
      const dateStr = moment.utc(item.timestamp).format(CHART_DATE_DEFAULT_FORMAT);
      const dateIndex = indexMap[dateStr];
      for (var breakDownIndex = 0; breakDownIndex < breakDownKeys.length; breakDownIndex++) {
        const breakDownKey = breakDownKeys[breakDownIndex];
        if (item.hasOwnProperty(breakDownKey) && dateBreakdown[dateIndex]) {
          dateBreakdown[dateIndex][breakDownKey] = item[breakDownKey];
        }
      }
    }

    let data = [];
    for (var i = 0; i < dateBreakdown.length; i++) {
      const breakdowns = dateBreakdown[i];
      const dateStr = breakdowns.date;
      const fullDateStr = breakdowns.fullDateStr;
      for (var bdi = 0; bdi < breakDownKeys.length; bdi++) {
        const breakDownKey = breakDownKeys[bdi];
        const breakDownType = breakDownTypes[breakDownKey];

        data.push({
          date: dateStr,
          fullDateStr: fullDateStr,
          value: breakdowns[breakDownKey],
          type: breakDownType,
        })
      }
    }
    return data;
  }

  generateHeader=(total)=> {
    let totalOrders = 0;
    let failedOrders = 0;
    if (total) {
      totalOrders = total.totalOrders || 0;
      failedOrders = total.failedOrders || 0;
    }

    let headerComponents = []
    
    if (failedOrders > 0) {
      headerComponents.push(<Divider type='vertical' key="divider-1"/>);
      headerComponents.push(
        <AntTooltip title="Number of failed orders" arrowPointAtCenter  key="Number of failed orders">
          <Tag color="blue">
            {Utils.formatIntNumber(failedOrders)}
          </Tag>
        </AntTooltip>
      );
      if (totalOrders > 0 ) {
        headerComponents.push(<Divider type='vertical' key="divider-2" />);
        headerComponents.push(
          <AntTooltip title="order failure rate" arrowPointAtCenter key="order failure rate">
            <Tag color="blue">{Utils.formatPercentageNumber(failedOrders / totalOrders)}</Tag>
          </AntTooltip>
        );
      }
    }

    return (
      <React.Fragment>
        <h3>
          Failed Orders Breakdown
          { headerComponents }
        </h3>
      </React.Fragment>
    );
  }

  render() {
    const { value, total, startTime, endTime } = this.props || {}
    if (!value) {
      return null;
    }

    if (Object.keys(value).length === 0) {
      // return null if empty
      return null;
    }

    const data = this.convertDataForChart(value, startTime, endTime);
    const header = this.generateHeader(total);
    const ds = new DataSet();
    const dv = ds
      .createView()
      .source(data)
      .transform({
        type: "percent",
        field: "value",
        dimension: "type",
        groupBy: ["date"],
        as: "percent"
      });

    const cols = {
      date: {
        range: [0, 1],
        tickCount: 10,
      },
    };

    const definitionRows = DEFINITIONS.map((d, index)=>{
      const l = `${d.label}: `;
      return (
        <div key={index}><b>{l}</b>{d.description}</div>
      )
    });


    return (
      <div>
        { header }
        <Chart height={400} data={dv} scale={cols} forceFit>
          <Legend />
          <Axis name="date" />
          <Axis name="value" 
            label={{
              formatter: (val) => {
                const l = Utils.formatIntNumber(val);
                return l;
              }
            }}
          />
          <Tooltip />
          <Geom
            hoverable={true}
            type="intervalStack"
            position="date*value"
            color={['type', ['#1C2541', '#FC8FBB','#FFD13D','#00A878','#8A69AD', '#6CA6C1', '#de425b']]}
            tooltip={["percent*value*type*fullDateStr", (percent, value, type, fullDateStr)=>{
              const perString = '(' + Utils.formatPercentageNumber(percent) + ")";
              return {
                name: type,
                value: value + perString,
                title: fullDateStr,
              }
            }]}
          />
        </Chart>
        <div style={{
          textAlign: 'left',
          padding: '10pt',
          backgroundColor: '#d3d3d3',
          margin: 'auto',
          maxWidth: '80%',
        }}>
          <h3>Definitions:</h3>
          {definitionRows}
        </div>
      </div>
    )
  }
}

export default FailedOrderChart;
