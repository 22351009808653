import React, { Component } from 'react';
import { Card, Tooltip } from 'antd';

class StatsCard extends Component {
  render() {
    const { data } = this.props || {};
    return (
      <Tooltip title={data.tooltip || ''}>
        <Card style={{marginBottom: '10pt'}}>
          <h3>{data.value || 0}</h3>
          <span>{data.label || ''}</span>
        </Card>
      </Tooltip>
    )
  }
}

export default StatsCard;
