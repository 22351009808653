import React, { Component } from 'react';
import OrdersView from '../components/orders/OrdersView';
import { VIEW_MODE } from '../utils/Consts';

/**
 * Orders Page for normal merchant user
 */
class Orders extends Component {
  render() {
    return (
      <OrdersView mode={VIEW_MODE.MERCHANT} />
    );
  }
}

export default Orders;
