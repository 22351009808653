import React, { Component } from 'react';
import {
  Avatar,
} from 'antd';
import {
  Link
} from 'react-router-dom';

import AccountManager from '../../utils/AccountManager';
import MerchantInfoManager from '../../utils/MerchantInfoManager';

import './SiderLogoHeader.css';

const DEFAUTL_PROPS = {
  logo: 'https://bieyangapp.com/en/img/logo.png',
  name: 'Admin',
};

class SiderLogoHeader extends Component {
  render() {
    let { logo, name } = DEFAUTL_PROPS;
    const accountInfo = AccountManager.loadAccountInfo();
    if (accountInfo 
      && accountInfo.type 
      && accountInfo.type.length > 0
      && accountInfo.type.toUpperCase() === 'MERCHANT'
      && accountInfo.merchantIds.length > 0) {
      // we assuming each account only has one merchant id for now,
      // change this logic if the situation has been changed.
      const merchant = MerchantInfoManager.merchantById(accountInfo.merchantIds[0]);
      if (merchant) {
        if (merchant.name) {
          name = merchant.name;
        }
        
        if (merchant.images && merchant.images.length > 0) {
          logo = merchant.images[0].full.url || ''; 
        }
      }
    }

    return (
      <div className="logo" id="logo">
        <Link to="/">
          <Avatar src={logo} alt="logo" />
          <h1>{name}</h1>
        </Link>
      </div>
    );
  }
}

export default SiderLogoHeader;
