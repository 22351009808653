import React, { Component } from 'react';
import OrdersView from '../components/orders/OrdersView';
import { VIEW_MODE } from '../utils/Consts';
/**
 * Orders Page for Admin user
 */
class AdminOrders extends Component {
  render() {
    return (
      <OrdersView mode={VIEW_MODE.ADMIN} />
    );
  }
}

export default AdminOrders;
