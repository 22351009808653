import React, { Component } from 'react';
import { Bar } from 'react-chartjs-2';
import moment from 'moment';
import Utils from '../../utils/Utils';
import { CHART_DATE_DEFAULT_FORMAT, CHART_DATE_FULL_FORMAT } from '../../utils/Consts';

class SummaryDailyChart extends Component {

  convertDataForChart =(chartData, startTime, endTime)=> {
    const dates = Utils.generateDateList(startTime, endTime);
    const fullDateStrs = Utils.generateDateList(startTime, endTime, CHART_DATE_FULL_FORMAT);
    let sales = Array(dates.length).fill(0);
    let orders = Array(dates.length).fill(0);
    chartData.forEach(cd => {
      // our data is in UTC time
      const dateStr = moment.utc(cd.timestamp).format(CHART_DATE_DEFAULT_FORMAT);
      const dateIndex = dates.indexOf(dateStr);
      sales[dateIndex] = cd.salesCents / 100.0;
      orders[dateIndex] = cd.ordersNumber;
    });

    const data = {
      datasets: [
        {
          type:'line',
          label: 'Orders',
          data: orders,
          fill: false,
          borderColor: '#2fc25b',
          backgroundColor: '#2fc25b',
          pointBorderColor: '#2fc25b',
          pointBackgroundColor: '#2fc25b',
          pointHoverBackgroundColor: '#2fc25b',
          pointHoverBorderColor: '#2fc25b',
          yAxisID: 'y-axis-2'
        },
        {
          type: 'bar',
          label: 'Sales',
          data: sales,
          fill: false,
          backgroundColor: '#3c8dbc',
          borderColor: '#3c8dbc',
          hoverBackgroundColor: '#3c8dbc',
          hoverBorderColor: '#3c8dbc',
          yAxisID: 'y-axis-1'
        },
      ],
      labels: {
        fullDateStrs: fullDateStrs,
      },
    };
    
    const options = {
      title: {
        display: true,
        text: 'Sales And Orders',
        position: 'top',
        fontSize: 16,
      },
      responsive: true,
      tooltips: {
        mode: 'label',
        callbacks: {
          label: function(tooltipItem, data) {
              var label = data.datasets[tooltipItem.datasetIndex].label || '';
              if (tooltipItem.datasetIndex === 1) {
                label += ': ' + Utils.formatCurrencyNumber(tooltipItem.yLabel);
              }  else {
                label += ': ' + Utils.formatIntNumber(tooltipItem.yLabel);
              }
              return label;
          },
          title: function(tooltipItem, data) {
            var title = data.labels.fullDateStrs[tooltipItem[0].index];
            return title;
          }
        }
      },
      elements: {
        line: {
          fill: false
        }
      },
      scales: {
        xAxes: [
          {
            display: true,
            gridLines: {
              display: false
            },
            labels: dates,
          }
        ],
        yAxes: [
          {
            type: 'linear',
            display: true,
            position: 'left',
            id: 'y-axis-1',
            scaleLabel: {
              display: true,
              labelString: 'Sales',
            },
            gridLines: {
              display: false
            },
            labels: {
              show: true
            },
            ticks: {
              // Include a dollar sign in the ticks
              callback: function(value, index, values) {
                return Utils.formatCurrencyNumber(value);
              }
            },
          },
          {
            type: 'linear',
            display: true,
            position: 'right',
            id: 'y-axis-2',
            scaleLabel: {
              display: true,
              labelString: 'Orders',
            },
            gridLines: {
              display: false
            },
            labels: {
              show: true
            },
            ticks: {
              // Include a dollar sign in the ticks
              callback: function(value, index, values) {
                return Utils.formatIntNumber(value);
              }
            },
          }
        ]
      }
    };
    
    const plugins = [];

    return { data, options, plugins };
  }

  render() {
    const { chartData, startTime, endTime } = this.props || {};
    if (!chartData || chartData.length === 0) {
      return null;
    }
    const { data, options, plugins } = this.convertDataForChart(chartData, startTime, endTime);
    return (
      <div>
        <Bar data={data}
          options={options}
          plugins={plugins}
        />
      </div>
    )
  }
}

export default SummaryDailyChart;
