import axios from 'axios';
import querystring from 'querystring';
import AccountManager from './AccountManager';
import { APP_ENV_TYPE } from './Consts';

class FifthAveAPI {
  static getAuthSessionHeaders = () => {
    const { userId, sessionKey } = AccountManager.loadSession();
    var headers = {
      'X-Session-Key': sessionKey,
      'X-Session-User': userId,
      'X-BXL-User-Agent': 'BXL Merchant Dashboard',
    };
    
    return headers;
  }

  /*
  * env: request send to production or develop
  */
  static APIEndPoint = (forceProduction) => {
    if (process.env.REACT_APP_ENV === APP_ENV_TYPE.PRODUCTION || forceProduction === true) {
      return 'https://5thave-prod.bybieyang.com/api/';
    } else {
      return 'https://5thave-dev.bybieyang.com/api/';
    }
  }

  static createInstance(withSession = false,  forceProduction = false) {
    return axios.create({
      baseURL: this.APIEndPoint(forceProduction),
      headers: withSession ? this.getAuthSessionHeaders() : {},
      timeout: 30000,
    });
  }

  static get(url, queries = {}, withSession = false, forceProduction = false) {
    // add tokens for authentications
    let qs = querystring.stringify(queries);
    if (qs.length > 0) {
      url = url + '?' + qs;
    }
    
    return this.createInstance(withSession, forceProduction).get(url);
  }

  static post(url, data = {}, withSession = false, forceProduction = false) {
    return this.createInstance(withSession, forceProduction).post(url, data);
  }

  static delete(url, withSession = false, forceProduction= false) {
    return this.createInstance(withSession, forceProduction).delete(url);
  }

  static loadMerchantList() {
    const queries = {
      _all: true,
    }
    return this.get('v1/merchants', queries, true);
  }

  static loadMerchantInfo(merchantId) {
    return this.get(`v1/merchants/${merchantId}`, {}, true);
  }

  static batchLoadProducts(productIds) {
    const data = {
      ids: productIds,
      availonly: false,
      fields: ["price"],
    };
    // force connect to production since we don't have all product info in dev database.
    return this.post(`v1/products/_batch/load`, data, true, true);
  }
}

export default FifthAveAPI;