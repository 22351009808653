import React, { Component } from 'react';
import { Pie } from 'react-chartjs-2';
import Utils from '../../utils/Utils';

class UserCompPieChart extends Component {

    convertDataForChart = (chartData) => {
        let data = [
            (chartData.visitors || 0), 
            (chartData.addedToCartUsers || 0),
            (chartData.newBuyers || 0),
            (chartData.repurchaseBuyers || 0)
        ];

        return data;
    }
    render () {
        const { chartData } = this.props || {};
        if (!chartData || Object.keys(chartData).length === 0) {
            return null;
        }

        const convertedData = this.convertDataForChart(chartData);
        const options = {
            legend: {
                position: 'bottom'   
            },
            tooltips: {
                callbacks: {
                  label: function(tooltipItem, data) {
                    var dataset = data.datasets[tooltipItem.datasetIndex];
                    var meta = dataset._meta[Object.keys(dataset._meta)[0]];
                    var total = meta.total;
                    var currentValue = dataset.data[tooltipItem.index];
                    var percentage = currentValue / total;
                    return `${Utils.formatIntNumber(currentValue)}(${Utils.formatPercentageNumber(percentage)})`;
                  },
                  title: function(tooltipItem, data) {
                    return data.labels[tooltipItem[0].index];
                  }
                }
              },
        }
        const data = {
            labels: ["Visitors", "Added_To_Cart Users", "New Buyers", "Repurchase Buyers"],
            datasets: [{
                label: "Visitors/Day",
                backgroundColor: [
                    '#ff8595',
                    '#9ae4da',
                    '#c1bae3',
                    '#d7f7b2'
                    ],
                data: convertedData
            }]
        }
        return(
            <div>
                <Pie 
                    data={ data } 
                    options= {options}
                />
            </div>
        )
    }
}

export default UserCompPieChart;