import axios from 'axios';
import querystring from 'querystring';
import AccountManager from './AccountManager';
import {APP_ENV_TYPE} from './Consts';

class MerchantAPI {
    static getAuthSessionHeaders = () => {
        const {userId, sessionKey} = AccountManager.loadSession();
        var headers = {
            'X-Session-Key': sessionKey,
            'X-Session-User': userId,
            'X-BXL-User-Agent': 'BXL Merchant Dashboard',
        };
        return headers;
    }

    /*
    * env: request send to production or develop
    */
    static APIEndPoint = () => {
        if (process.env.REACT_APP_ENV === APP_ENV_TYPE.PRODUCTION) {
            return 'https://merchant.bybieyang.com/api/';
        } else {
            return 'https://merchant-dev.bybieyang.com/api/';
        }
    }

    static createInstance(withSession = false) {
        return axios.create({
            baseURL: this.APIEndPoint(),
            headers: withSession ? this.getAuthSessionHeaders() : {},
            timeout: 30000,
        });
    }

    static get(url, queries = {}, withSession = false) {
        // add tokens for authentications
        let qs = querystring.stringify(queries);
        if (qs.length > 0) {
            url = url + '?' + qs;
        }
        return this.createInstance(withSession).get(url);
    }

    static post(url, data = {}, withSession = false) {
        return this.createInstance(withSession).post(url, data);
    }

    static delete(url, withSession = false) {
        return this.createInstance(withSession).delete(url);
    }

    static postLogin({username, password, phone}) {
        const data = {
            username,
            password,
            phone,
        }
        return this.post('v1/account/signin', data, false);
    }

    static getAdminSummary({merchantId, startTime, endTime}) {
        const queries = {
            st: startTime,
            et: endTime,
        }
        return this.get(`v1/summary/merchant/${merchantId}`, queries, true);
    }

    static getSummary({startTime, endTime}) {
        const queries = {
            st: startTime,
            et: endTime,
        }
        return this.get(`v1/summary/`, queries, true);
    }

    static getAdminCustomers({merchantId, startTime, endTime}) {
        const queries = {
            st: startTime,
            et: endTime,
        }
        return this.get(`v1/customers/merchant/${merchantId}`, queries, true);
    }

    static downloadOrdersCsv({merchantId, startTime, endTime}) {
        const queries = {
            st: startTime,
            et: endTime,
        };
        return this.get(`v1/summary/orders/csv/${merchantId}`, queries, true);
    }

    static getAdmimOrders({merchantId, startTime, endTime}) {
        const queries = {
            st: startTime,
            et: endTime,
        }
        return this.get(`v1/orders/merchant/${merchantId}`, queries, true);
    }

    static getCustomers({startTime, endTime}) {
        const queries = {
            st: startTime,
            et: endTime,
        }
        return this.get('v1/customers/', queries, true);
    }

    static getOrders({startTime, endTime}) {
        const queries = {
            st: startTime,
            et: endTime,
        }
        return this.get(`v1/orders/`, queries, true);
    }

    static getAccount() {
        return this.get('v1/account', {}, true);
    }
}

export default MerchantAPI;