import React, { Component } from 'react';

import {
  Col,
  Row,
  DatePicker,
} from 'antd';

import moment from 'moment';

import MerchantSelector from './MerchantSelector';
import AccountManager from '../../utils/AccountManager';
import FilterInfoManager from '../../utils/FilterInfoManager';

class FilterPickerRow extends Component {

  generatePresetDateRange = () => {
    const endTime = moment().endOf('day').subtract(1, 'days');
    const ranges = {
      'Yesterday': [moment().startOf('day').subtract(1, 'days'), endTime], 
      'Last 7 days': [moment().startOf('day').subtract(7, 'days'), endTime],
      'Last 30 days': [moment().startOf('day').subtract(30, 'days'), endTime],
      'Last 90 days': [moment().startOf('day').subtract(90, 'days'), endTime],
    };
    return ranges;
  }

  handleMerchantSelectorChanged =(merchantId)=> {
    if (AccountManager.isAdminLogin() && this.props.onMerchantChanged) {
      FilterInfoManager.updateFilterCache({merchantId});
      this.props.onMerchantChanged(merchantId);
    }
  }

  handleRangePickerChange = (rangePickerValue) => {
    if (rangePickerValue.length === 2 && this.props.onDateRangeChanged) {
      const info = {
        startTime: rangePickerValue[0].startOf('day').valueOf(),
        endTime: rangePickerValue[1].startOf('day').valueOf(),
      };
      FilterInfoManager.updateFilterCache(info);
      this.props.onDateRangeChanged(info);
    }
  }

  render() {
    const { startTime, endTime, merchantId } = this.props || {};

    let merchantSelector = null;
    // only show mechant selector for admin user
    if (AccountManager.isAdminLogin()) {
      merchantSelector = (
        <MerchantSelector value={merchantId}
          name='merchantId'
          onChange={this.handleMerchantSelectorChanged} />
      );
    }

    const rangePickerValue = [moment(startTime), moment(endTime)];
    const presetDateRange = this.generatePresetDateRange();
    return (
      <Row gutter={16} type="flex" justify="end">
        <Col xs={24} sm={12} md={8} lg={8} xl={8}>
          { merchantSelector }
        </Col>
        <Col xs={24} sm={12} md={10} lg={8} xl={6}>
          <DatePicker.RangePicker style={{margin: "0 2%"}}
            onChange={this.handleRangePickerChange} 
            ranges={presetDateRange}
            value={rangePickerValue}
            allowClear={false}/>
        </Col>
      </Row>
    )
  }
}

export default FilterPickerRow;
