import React, { Component } from 'react';
import CustomersView from '../components/customers/CustomersView'
import { VIEW_MODE } from '../utils/Consts';

/**
 * customers page for normal merchant user
 */
class Customers extends Component {
  render() {
    return (
        <CustomersView mode={VIEW_MODE.MERCHANT} />
    );
  }
}

export default Customers;
