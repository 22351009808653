import React, { Component } from 'react';
import './App.css';
import {
  BrowserRouter as Router,
  Route,
  Redirect,
} from 'react-router-dom';
import { track as BizChartTrack } from "bizcharts";
import Summary from './pages/Summary';
import AdminSummary from './pages/AdminSummary';
import Orders from './pages/Orders';
import AdminOrders from './pages/AdminOrders';
import Customers from './pages/Customers';
import AdminCustomers from './pages/AdminCustomers';
import Login from './pages/Login';
import 'antd/dist/antd.css';
import AccountManager from './utils/AccountManager';
import { GATEWAY_CONFIG } from './utils/Consts';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// if user didn't login and try to access the protected page,
// this route will auto redirect to login page
const AdminProtectedRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={props => (
    AccountManager.isAdminLogin() ? (
      <Component {...props}/>
    ) : (
      <Redirect to={{
        pathname: GATEWAY_CONFIG.PATH.HOME,
        state: { from: props.location }
      }}/>
    )
  )}/>
)

const NormalProtectedRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={props => (
    AccountManager.isLogin() ? (
      <Component {...props}/>
    ) : (
      <Redirect to={{
        pathname: GATEWAY_CONFIG.PATH.HOME,
        state: { from: props.location }
      }}/>
    )
  )}/>
)

// all the access to home will be redirected to sign page for now.
const HomeRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={props => (
    <Redirect to={{
      pathname: GATEWAY_CONFIG.PATH.LOGIN,
      state: { from: props.location }
    }}/>
  )}/>
)

// if user did login and try to access the SignInCheck page,
// this route will auto redirect to summary page
const SignInCheckRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={props => (
    AccountManager.isAdminLogin() ? (
      <Redirect to={{
        pathname: GATEWAY_CONFIG.PATH.ADMIN_SUMMARY,
        state: { from: props.location }
      }} />
    ) : (
      AccountManager.isLogin() ? (
        <Redirect to={{
          pathname: GATEWAY_CONFIG.PATH.SUMMARY,
          state: { from: props.location }
        }}/>
      ) : (
        <Component {...props}/>
      )
    )
  )}/>
)

class App extends Component {
  render() {
    BizChartTrack(false);
    return (
      <div className="App">
        <Router>
          <div>
            <ToastContainer />
            <HomeRoute exact path={GATEWAY_CONFIG.PATH.HOME} />
            <SignInCheckRoute exact path={GATEWAY_CONFIG.PATH.LOGIN} component={Login}/>
            <NormalProtectedRoute exact path={GATEWAY_CONFIG.PATH.SUMMARY} component={Summary} />
            <AdminProtectedRoute exact path={GATEWAY_CONFIG.PATH.ADMIN_SUMMARY} component={AdminSummary}/>
            <NormalProtectedRoute exact path={GATEWAY_CONFIG.PATH.ORDERS} component={Orders} />
            <AdminProtectedRoute exact path={GATEWAY_CONFIG.PATH.ADMIN_ORDERS} component={AdminOrders} />
            <NormalProtectedRoute exact path={GATEWAY_CONFIG.PATH.CUSTOMERS} component={Customers} />
            <AdminProtectedRoute exact path={GATEWAY_CONFIG.PATH.ADMIN_CUSTOMERS} component={AdminCustomers} />
          </div>
        </Router>
      </div>
    );
  }
}

export default App;
