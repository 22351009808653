import CacheDataManager from './CacheDataManager';
import MerchantInfoManager from './MerchantInfoManager';
import MerchantAPI from './MerchantAPI';

class AccountManager {
  static saveSession(userId, sessionKey) {
    CacheDataManager.saveUserId(userId);
    CacheDataManager.saveSessionKey(sessionKey);
  }

  static loadSession() {
    const userId = CacheDataManager.loadUserId();
    const sessionKey = CacheDataManager.loadSessionKey();
    return { userId, sessionKey };
  }

  static saveAccountInfo(account) {
    CacheDataManager.saveAccountInfo(account);
  }

  static loadAccountInfo() {
    return CacheDataManager.loadAccountInfo();
  }

  static updateAccountInfo () {
    MerchantAPI.getAccount().then((response) => {
      if (response.data) {
        this.saveAccountInfo(response.data);
        MerchantInfoManager.updateMerchantList();
      }
    })
    .catch((error) => {
      console.error(error);
    });
  }

  static isLogin() {
    const { userId, sessionKey } = this.loadSession() || {};
    const account = this.loadAccountInfo() || {}; 
    if (account.userId !== userId) {
      // once the account info is not match use the session user id, then logout user and clean all the cache data.
      this.logout();
      return false;
    }
    return userId && userId.length > 0 && sessionKey && sessionKey.length > 0 && account && account.type && account.type.length > 0;
  }

  static isAdminUser() {
    const account = this.loadAccountInfo();
    if (account && account.type && account.type.length > 0 && account.type.toUpperCase() === 'ADMIN') {
      return true;
    }
    return false;
  }

  static isAdminLogin() {
    return this.isLogin() && this.isAdminUser();
  }

  static logout() {
    // clear the user_id and session_key from cache
    CacheDataManager.saveUserId('');
    CacheDataManager.saveSessionKey('');
    CacheDataManager.saveAccountInfo({});
    // reset the filter cache
    CacheDataManager.saveFilter({});
  }
}

export default AccountManager;