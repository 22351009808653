import React from 'react';
import { Row, Col } from 'antd';
import { Form,
  Icon,
  Input,
  Button,
} from 'antd';
import { withRouter } from 'react-router-dom';

import { GATEWAY_CONFIG } from '../utils/Consts';
import MerchantAPI from '../utils/MerchantAPI';
import AccountInfoManager from '../utils/AccountManager';
import MerchantInfoManager from '../utils/MerchantInfoManager';
import { toast } from 'react-toastify';
import './Login.css';

class Login extends React.Component {
  state = {
    username: '',
    password: '',
    isLoginLoading: false,
  }

  handleChange =(event)=> {
    const { name, value } = event.target || {};
    this.setState({[name]: value});
  }

  updateAccountInfo =()=> {
    this.setState({
      isLoginLoading: true,
    });
    MerchantAPI.getAccount().then((response) => {
      this.setState({
        isLoginLoading: false,
      });
      if (response.data) {
        AccountInfoManager.saveAccountInfo(response.data);
        MerchantInfoManager.updateMerchantList();
        this.props.history.push(GATEWAY_CONFIG.PATH.HOME);
      } else {
        toast.error("Failed to get account info! ", {
          position: toast.POSITION.TOP_LEFT
        })  
      }
    })
    .catch((error) => {
      this.setState({
        isLoginLoading: false,
      });
      toast.error("Failed to get account info! " + error.toString(), {
        position: toast.POSITION.TOP_LEFT
      })
      console.error(error);
    });
  }

  handleSubmitClick =()=> {
    const { username, password } = this.state || {};
    this.setState({
      isLoginLoading: true,
    });
    MerchantAPI.postLogin({username, password}).then((response) => {
      this.setState({
        isLoginLoading: false,
      });
      if (response && response.data) {
        const { userId, sessionKey } = response.data || {};
        AccountInfoManager.saveSession(userId, sessionKey);
        this.updateAccountInfo();
      }
    })
    .catch((error) => {
      this.setState({
        isLoginLoading: false,
      });
      toast.error("❌ Login Failed! " + error.toString(), {
        position: toast.POSITION.TOP_LEFT
      })
      console.error(error);
    });
  }

  render() {
    const { username, password, isLoginLoading } = this.state || {};
    const disableSumbitBtn = username.length === 0 || password.length === 0;
    return (
      <div style={{ paddingTop: '20vh', background: '#fff', textAlign: 'center' }}>
        <Row>
          <Col>
            <h2>Beyond Marketplace Merchant Dashboard</h2>
          </Col>
        </Row>
        <Row type="flex" justify="center" >
          <Col>
            <Form onSubmit={this.handleSubmit} className="login-form">
              <Form.Item>
                <Input prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />} 
                  placeholder="Username" 
                  value={username}
                  name="username"
                  onChange={this.handleChange}
                />
              </Form.Item>
              <Form.Item>
                <Input prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />} 
                  type="password" 
                  placeholder="Password" 
                  name="password"
                  value={password}
                  onChange={this.handleChange}
                />
              </Form.Item>
              <Form.Item>
                <Button type="primary" htmlType="submit" className="login-form-button"
                  icon="login"
                  disabled={disableSumbitBtn}
                  onClick={this.handleSubmitClick}
                  loading={isLoginLoading}>
                  Log in
                </Button>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </div>      
    )
  }
}

export default withRouter(Login);