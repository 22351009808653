import React from 'react';
import { 
  Layout,
  Menu,
  Icon,
  Col,
  Row,
} from 'antd';
import { withRouter } from 'react-router-dom';
import AccountManager from '../../utils/AccountManager';
import { GATEWAY_CONFIG } from '../../utils/Consts';

const SubMenu = Menu.SubMenu;
const MenuItemGroup = Menu.ItemGroup;

class AppHeader extends React.Component {

  handleLogout =()=> {
    AccountManager.logout();
    this.props.history.push(GATEWAY_CONFIG.PATH.HOME);
  }

  render () {
    let headerContent = <h2>Merchant Dashboard</h2>
    if (AccountManager.isLogin()) {
      headerContent = (
        <Row type="flex" justify="end">
          <Col>
            <Menu
              mode="horizontal"
            >
              <SubMenu title={<span><Icon type="bars" />Menu</span>}>
                <MenuItemGroup title="Account">
                  <Menu.Item key="logout" onClick={this.handleLogout}>
                    Log Out
                  </Menu.Item>
                </MenuItemGroup>
              </SubMenu>
            </Menu>
          </Col>
        </Row>
      )
    }
    return (
      <Layout.Header style={{ background: '#fff' }} >
        { headerContent }
      </Layout.Header>
    )
  }
}

export default withRouter(AppHeader);
