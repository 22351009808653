import React, { Component } from 'react';
import {
  Link,
  withRouter,
} from 'react-router-dom';
import {
  Layout,
  Menu,
  Icon,
} from 'antd';

import SiderLogoHeader from './SiderLogoHeader';
import {
  GATEWAY_CONFIG
} from '../../utils/Consts';
import AccountManager from '../../utils/AccountManager';

const MENU_LIST = [
  {
    name: 'Dashboard',
    icon: "line-chart",
    adminLink: GATEWAY_CONFIG.PATH.ADMIN_SUMMARY,
    normalLink: GATEWAY_CONFIG.PATH.SUMMARY,
  },
  {
    name: 'Orders',
    icon: "shopping-cart",
    adminLink: GATEWAY_CONFIG.PATH.ADMIN_ORDERS,
    normalLink: GATEWAY_CONFIG.PATH.ORDERS,
  },
  {
    name: 'Customers',
    icon: "team",
    adminLink: GATEWAY_CONFIG.PATH.ADMIN_CUSTOMERS,
    normalLink: GATEWAY_CONFIG.PATH.CUSTOMERS,
  },
]

class LeftSider extends Component {
  generateMenuItems = () => {
    let selectedKeys = [];
    const menuItems = MENU_LIST.map((mi)=>{
      const href = AccountManager.isAdminLogin()? mi.adminLink : mi.normalLink;
      if (this.props.location.pathname === mi.adminLink || this.props.location.pathname === mi.normalLink) {
        selectedKeys = [mi.name];
      }
      return (
        <Menu.Item key={mi.name} 
          style={{textAlign: 'left'}}
        >
          <Link to={href}>
            <Icon type={mi.icon} theme="outlined" />
            <span>{mi.name}</span>
          </Link>
        </Menu.Item>
      )
    });
    return { menuItems, selectedKeys };
  }

  render() {
    const { menuItems, selectedKeys } = this.generateMenuItems();
    return (
      <Layout.Sider
        collapsible
        breakpoint="lg"
        style={{zIndex:2001}}
      >
        <SiderLogoHeader />
        <Menu theme="dark" mode="inline" defaultOpenKeys={[MENU_LIST[0].name]}
          selectedKeys={selectedKeys}>
          { menuItems }
        </Menu>
      </Layout.Sider>
    );
  }
}

export default withRouter(LeftSider);
