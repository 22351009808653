
const LOCAL_CACHE_KEY = {
  MERCHANT_LIST: 'merchant_list',
  SESSION_KEY:'session_key',
  USER_ID: 'user_id',
  ACCCOUN_INFO: 'account_info',
  FILTER: 'filter',
}

class CacheDataManager {
  static saveMerchantList(merchantList) {
    if (merchantList) {
      localStorage.setItem(LOCAL_CACHE_KEY.MERCHANT_LIST, JSON.stringify(merchantList));
    }
  }

  static loadMerchantList() {
    return JSON.parse(localStorage.getItem(LOCAL_CACHE_KEY.MERCHANT_LIST) || '[]');
  }

  static saveUserId(userId) {
    localStorage.setItem(LOCAL_CACHE_KEY.USER_ID, userId);
  }

  static loadUserId() {
    return localStorage.getItem(LOCAL_CACHE_KEY.USER_ID);
  }

  static saveSessionKey(sessionKey) {
    localStorage.setItem(LOCAL_CACHE_KEY.SESSION_KEY, sessionKey);
  }

  static loadSessionKey() {
    return localStorage.getItem(LOCAL_CACHE_KEY.SESSION_KEY);
  }

  static saveAccountInfo(accountInfo) {
    localStorage.setItem(LOCAL_CACHE_KEY.ACCCOUN_INFO, JSON.stringify(accountInfo));
  }

  static loadAccountInfo() {
    return JSON.parse(localStorage.getItem(LOCAL_CACHE_KEY.ACCCOUN_INFO) || '{}');
  }

  static saveFilter(filter) {
    localStorage.setItem(LOCAL_CACHE_KEY.FILTER, JSON.stringify(filter));
  }

  static loadFilter() {
    return JSON.parse(localStorage.getItem(LOCAL_CACHE_KEY.FILTER) || '{}');
  }
}


export default CacheDataManager;