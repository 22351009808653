import React, { Component } from 'react';
import { Table } from 'antd';
import Utils from '../../utils/Utils';

class SummaryBrandsTable extends Component {

  convertDataForTable = (data) => {
    const columns = [{
      title: 'Brand',
      dataIndex: 'brandName',
      key: 'brandName',
    }, {
      title: 'Total Units',
      dataIndex: 'totalUnits',
      key: 'totalUnits',
    }, {
      title: 'Total Amount',
      dataIndex: 'totalSales',
      key: 'totalSales',
    }];
    
    let dataSource = [];
    if (!data) {
      return { dataSource, columns };
    }

    dataSource = data.map((d) => {
      return {
        brandName: d.brandName,
        totalUnits: Utils.formatIntNumber(d.totalUnits),
        totalSales: Utils.formatCurrencyNumber(d.totalSalesCents / 100.0),
        key: d.brandName,
      }
    });

    return { dataSource, columns };
  }

  render() {
    const { data } = this.props || {};
    if (Object.keys(data).length === 0) {
      // return null if empty
      return null;
    }
    const { dataSource, columns } = this.convertDataForTable(data);
    return (
      <React.Fragment>
        <h4> TOP-SALE BRANDS </h4>
        <Table dataSource={dataSource}
          columns={columns} 
          pagination={false}
        />
      </React.Fragment>
    )
  }
}

export default SummaryBrandsTable;
