import FifthAveAPI from './FifthAveAPI';
import CacheDataManager from './CacheDataManager';
import AccountManager from './AccountManager';

const DEFAULT_MERCHANTS_LIST = [
  {
    "id": "7556fe83-a62c-6ad7-308d-dfa7c73ea220",
    "name": "100% Pure"
  },
  {
    "id": "98d3cc00-5772-a89e-2744-b3ad74d3a2a4",
    "name": "6PM"
  },
  {
    "id": "dba7b5c4-bd91-e2ca-c86a-de6d153530cd",
    "name": "Alexander Wang"
  },
  {
    "id": "aeb068cb-1f7d-603f-337d-44c6ea3b1216",
    "name": "Algenist"
  },
  {
    "id": "5a5c963d-50c4-2f92-e197-f12958cd5648",
    "name": "Ashford"
  },
  {
    "id": "390048df-fa10-c517-3a96-8552599d14ef",
    "name": "b-glowing"
  },
  {
    "id": "6dc1e761-126f-b2a1-cfb8-ac8b26f67602",
    "name": "Bally"
  },
  {
    "id": "8c43cf1e-208f-3db2-2255-401005035872",
    "name": "Barneys New York"
  },
  {
    "id": "6b10f446-024d-3120-c1f2-3826d385b844",
    "name": "Barneys New York Warehouse"
  },
  {
    "id": "e1fe19b7-cee8-d04b-10d0-c16d40782476",
    "name": "Besame"
  },
  {
    "id": "34b7c946-2aec-d75b-66f0-81fcea0ce875",
    "name": "Bloomingdale's"
  },
  {
    "id": "4c043e06-a027-edf4-d040-48f1276c75be",
    "name": "Brooks Brothers"
  },
  {
    "id": "0d4367ab-d544-43e3-1a7f-de7d256d0c67",
    "name": "Dareen Hakim"
  },
  {
    "id": "acafddf0-ad6c-c898-f2ff-1c5341a4fa38",
    "name": "DKNY"
  },
  {
    "id": "0c72f8df-0cd0-09c7-69c2-7b98c8261c64",
    "name": "Dr. Brandt"
  },
  {
    "id": "b5cfeb47-444a-0327-5caa-86f1014e50c7",
    "name": "eBags"
  },
  {
    "id": "a7a8c34b-2c0d-705d-5de2-d86987d62990",
    "name": "Eddie Bauer"
  },
  {
    "id": "3015c759-5c09-06c4-9c74-76458ce1c0fc",
    "name": "Everlane"
  },
  {
    "id": "26415a45-e02c-6a77-1ed7-f40023f03163",
    "name": "Finish Line"
  },
  {
    "id": "19ccde73-4b18-d269-ce77-93ae7729b8c6",
    "name": "Foreo"
  },
  {
    "id": "a81911c5-0024-93ae-e685-999efd1d0755",
    "name": "Forzieri"
  },
  {
    "id": "40fbc8d5-0efc-ee81-332a-02e0fd2a8352",
    "name": "GNC"
  },
  {
    "id": "6ff389a2-2a13-a666-15c7-9c0688b1043c",
    "name": "GREATS"
  },
  {
    "id": "a220bc15-ea6d-56b9-49bd-c215da636a5a",
    "name": "Gymboree"
  },
  {
    "id": "096f7103-afc4-8614-84d3-9fe00863d1fc",
    "name": "Harrods"
  },
  {
    "id": "24c28957-861e-b03a-9770-36dbb50daae9",
    "name": "Juice Beauty"
  },
  {
    "id": "edd071ba-65f8-ba8b-04f4-e8dbe1f8b846",
    "name": "Kate Somerville"
  },
  {
    "id": "442f5994-54bb-e0a6-6484-01821a175494",
    "name": "Kate Spade"
  },
  {
    "id": "418de407-b1fc-b0f6-1fd6-da1941c55386",
    "name": "Lelo"
  },
  {
    "id": "ea4800b4-807f-1963-9fc4-0d2016a29fb6",
    "name": "LookFantastic US"
  },
  {
    "id": "ca8992c0-0da5-f72e-c263-e04648cf02f3",
    "name": "Lord & Taylor"
  },
  {
    "id": "25c3900e-3c52-9c8b-e2fd-639ae1932728",
    "name": "Madewell"
  },
  {
    "id": "0c32169d-33a8-c50e-8443-ada9dd24e8c7",
    "name": "Moosejaw"
  },
  {
    "id": "ec91ae99-11f2-e069-279a-f6e2bae278db",
    "name": "New Balance Outlet"
  },
  {
    "id": "b80f37f8-55ea-eabb-e67b-a9041e92d260",
    "name": "Nine West"
  },
  {
    "id": "9d5ea3ab-b9b3-9310-eba3-a47c5d3f01ac",
    "name": "Perricone MD"
  },
  {
    "id": "97f01347-3d82-cc95-a7c5-824b2841721f",
    "name": "Peter Thomas Roth"
  },
  {
    "id": "99cad999-3de7-ca5f-1596-82a77c2f3dbd",
    "name": "Pixie Market"
  },
  {
    "id": "1bd8ad5e-cf18-775a-ef9a-b09009cc6c31",
    "name": "Rebecca Minkoff"
  },
  {
    "id": "ebcfa01b-b4f8-474f-8374-180f390a5b05",
    "name": "Rothy's"
  },
  {
    "id": "1942568d-5265-9cd5-4123-3c6abc8f43ae",
    "name": "Saks Fifth Avenue"
  },
  {
    "id": "f0d9db56-1431-302d-5cf2-19e7fb098f6b",
    "name": "Saks OFF 5TH"
  },
  {
    "id": "65474ec0-2dc1-0373-d40a-b31b5ae7f113",
    "name": "SkinCareRx"
  },
  {
    "id": "ed1b8ef3-daac-2199-a696-1320af6dd884",
    "name": "Smashbox Cosmetics"
  },
  {
    "id": "99bac96b-cd79-bafc-61d7-6d09f7c1fc4d",
    "name": "Stowaway Cosmetics"
  },
  {
    "id": "33a7be4a-f99f-43e6-7d41-24afd9b828e0",
    "name": "Winky Lux"
  },
  {
    "id": "46245f73-939b-718f-da04-4099b2f144ae",
    "name": "YOOX"
  },
  {
    "id": "725755ff-5f38-95fd-eec5-b3535bce2fd5",
    "name": "Zappos"
  },
  {
    "id": "9c43cfa1-062f-fa4d-822b-de650b4c4b00",
    "name": "Zappos Luxury"
  },
];

const proDna = {id: 'eeb6eb5a-7eed-29df-b59e-937e64a7e400',
  name: 'ProD.N.A'}
class MerchantInfoManager {
  /**
   * - For Admin user, We download all the merchant list
   * - For Normal merchant, we only need down it's own
   */
  static updateMerchantList() {
    if (AccountManager.isAdminUser()) {
      this.updateWholeMerchantList();
    } else {
      const account = AccountManager.loadAccountInfo();
      if (account && account.merchantIds && account.merchantIds.length > 0) {
        // we assume only one merchant will be bonded to a normal account
        // change this logic if the situation has changed
        this.updateMerchantInfo(account.merchantIds[0]);
      }
    }
  }

  /**
   * update whole merchant list, should be used only by admin user
   */
  static updateWholeMerchantList() {
    FifthAveAPI.loadMerchantList().then((response) => {
      if (response && response.data && response.data.merchants) {
        CacheDataManager.saveMerchantList(response.data.merchants);
      }
    })
    .catch((error) => {
      console.error(error);
    });
  }

  /**
   * update merchant info, should be used for normal merchant user
   */
  static updateMerchantInfo(merchantId) {
    FifthAveAPI.loadMerchantInfo(merchantId).then((response) => {
      if (response && response.data) {
        CacheDataManager.saveMerchantList([response.data]);
      }
    })
    .catch((error) => {
      console.error(error);
    });
  }

  static merchantListForSelector() {
    const merchantList = CacheDataManager.loadMerchantList(); 
    if (merchantList && merchantList.length > 0) {
      const result = merchantList.map((m)=> {
        return ({
          id: m.id,
          name: m.name,
        });
      });
      return result;
    } else {
      return DEFAULT_MERCHANTS_LIST; 
    }
  }

  static merchantById(merchantId) {

    /*
     * merchants table saves name as 'Paris Hilton' however
     * we would like it to say 'ProD.N.A'
     */

    if (merchantId === 'eeb6eb5a-7eed-29df-b59e-937e64a7e400') {
      return proDna;
    }
    let result = {};
    const merchantList = CacheDataManager.loadMerchantList();
    result = merchantList.find((m)=> {
      return m && m.id && m.id === merchantId; 
    });
    return result;
  }
}

export default MerchantInfoManager;