import React, { Component } from 'react';
import {
  Chart, 
  Coord,
  Label,
  Tooltip, 
  Geom,
  Legend,
  Guide,
} from "bizcharts";
import DataSet from "@antv/data-set";
import Utils from '../../utils/Utils';


class UserConversionFunnel extends Component {
  
  convertDataForChart = (chartData) => {
    let data = [
      {
        action: "VISITORS",
        pv: chartData.visitors || 0,
        pvStr: Utils.formatIntNumber(chartData.visitors || 0)
      },
      {
        action: "ADDED_TO_CART",
        pv: chartData.addedToCart || 0,
        pvStr: Utils.formatIntNumber(chartData.addedToCart || 0)
      },
      {
        action: "PURCHASED",
        pv: chartData.purchased || 0,
        pvStr: Utils.formatIntNumber(chartData.purchased || 0)
      }
    ];

    return data;
  }

  render() {
    const { Text } = Guide;
    const { DataView } = DataSet;
    const { chartData } = this.props || {};

    if (!chartData || Object.keys(chartData).length === 0) {
      return null;
    }

    let data = this.convertDataForChart(chartData);

    const dv = new DataView().source(data);
    dv.transform({
      type: "percent",
      field: "pv",
      dimension: "action",
      as: "percent"
    });
    data = dv.rows;
    const cols = {
      percent: {
        nice: false
      }
    };

    return (
        <div>
        <h2>Conversion Funnel</h2>
        <Chart
            height={400}
            data={data}
            scale={cols}
            padding={[20, 120, 95]}
            forceFit
            >
            <Tooltip
                showTitle={false}
                itemTpl="<li data-index={index} style=&quot;margin-bottom:4px;&quot;><span style=&quot;background-color:{color};&quot; class=&quot;g2-tooltip-marker&quot;></span>{name}<br/><span style=&quot;padding-left: 16px&quot;>Total：{pv}</span><br/><span style=&quot;padding-left: 16px&quot;>Percentage：{percent}</span><br/></li>"
            />
            <Coord type="rect" transpose scale={[1, -1]} />
            <Legend />
            <Guide>
                {data.map(obj => {
                return (
                    <Text
                    key={obj.action}
                    top={true}
                    position={{
                        action: obj.action,
                        percent: "median"
                    }}
                    content={Utils.formatPercentageNumber(obj.percent)}
                    style={{
                        fill: "#333333",
                        fontSize: "12",
                        textAlign: "center",
                    }}
                    />
                );
                })}
            </Guide>
            <Geom
                type="intervalSymmetric"
                position="action*percent"
                shape="funnel"
                color={[
                "action",
                ['#ff8595','#9ae4da','#c1bae3']
                ]}
                tooltip={[
                "action*percent*pvStr",
                (action, percent, pvStr) => {
                    return {
                    name: action,
                    percent: Utils.formatPercentageNumber(percent),
                    pv: pvStr
                    };
                }
                ]}
            >
                <Label
                content={[
                    "action*pvStr",
                    (action, pvStr) => {
                    return action + ": " + pvStr;
                    }
                ]}
                offset={35}
                labeLine={{
                    lineWidth: 1,
                    stroke: "rgba(0, 0, 0, 0.15)"
                }}
                />
            </Geom>
        </Chart>
        </div>
    )
  }
}

export default UserConversionFunnel;
