import React, { Component } from 'react';
import {
  Col,
  Row,
  Spin,
} from 'antd';
import { toast } from 'react-toastify';

import BasicLayout from '../common/BasicLayout';
import FilterPickerRow from '../common/FilterPickerRow';
import StatsCard from '../common/StatsCard';
import MerchantAPI from '../../utils/MerchantAPI';
import AccountManager from '../../utils/AccountManager';
import { VIEW_MODE } from '../../utils/Consts';
import VisitorsLineGraph from './VisitorsLineGraph';
import UserCompPieChart from './UserCompPieChart';
import UserCompBarGraph from './UserCompBarGraph';
import UserConversionFunnel from './UserConversionFunnel';
import FilterInfoManager from '../../utils/FilterInfoManager';
import Utils from '../../utils/Utils';

class CustomersView extends Component {
    constructor(props) {
        super(props);
        const cachedFilter = FilterInfoManager.loadCachedFilter();
        this.state = {
            loadingCustomerStats: false,
            endTime: cachedFilter.endTime,
            startTime: cachedFilter.startTime,
            merchantId: cachedFilter.merchantId || null,
        }
    }

    loadCustomersStats = () => {
        //integration with API
        let {merchantId, startTime, endTime } = this.state || {};
        const {mode} = this.props;
        if (!merchantId && mode === VIEW_MODE.ADMIN) {
            this.setState({
                loadingCustomerStats: false,
            });
            return;
        }
        
        this.setState({
            loadingCustomerStats: true,
        });

        // need to convert tthe date range to UTC time, since data is in UTC time
        // get timezoneOffset from UTC in milliseconds
        const timezoneOffset = new Date().getTimezoneOffset() * 60 * 1000;
        startTime -= timezoneOffset;
        endTime -= timezoneOffset;
        
        if (mode === VIEW_MODE.ADMIN) {

            MerchantAPI.getAdminCustomers({merchantId,startTime,endTime}).then((response) => {
                this.setState({
                    loadingCustomerStats: false,
                    customerStats: response.data,
                });
            })
            .catch((error) => {
                console.error(error);
                this.setState ({
                    loadingCustomerStats: false,
                });
                toast.error('Loading Customer Stats Failed.  ' + error.toString());
            });
        }
        else {
            MerchantAPI.getCustomers({startTime, endTime}).then((response) => {
                this.setState({
                    loadingCustomerStats: false,
                    customerStats: response.data,
                });
            })
            .catch((error) => {
                console.error(error);
                this.setState ({
                    loadingCustomerStats: false,
                });
                toast.error('Loading Customer Stats Failed.  ' + error.toString());
            })
        }
    }

    componentDidMount() {
        AccountManager.updateAccountInfo();
        this.loadCustomersStats();
    }

    handleDateRangeChanged = (dateRange) => {
        const { startTime, endTime } = dateRange || {};
        this.setState({
            startTime,
            endTime,
        },
        () => {
            this.loadCustomersStats();
        })
    }

    handleMerchantSelectorChanged = (merchantId) => {
        this.setState({
          merchantId,
        },
        () => {
            this.loadCustomersStats();
        });
      }

    convertCustomerStatsForCards = (customerStats) => {
        let data = [];
        if (!customerStats || !customerStats.sumCustomers) {
            return data;
        }

        if (customerStats.sumCustomers.visitors) {
            data.push({
                label: 'Visitors',
                value: Utils.formatIntNumber(customerStats.sumCustomers.visitors || 0),
                tooltip: 'Total Number of Visitors'
            });
        }
        if (customerStats.sumCustomers.newBuyers) {
            data.push({
                label: 'New Buyers',
                value: Utils.formatIntNumber(customerStats.sumCustomers.newBuyers || 0),
                tooltip: 'Total Number of New Buyers'
            });
        }
        if (customerStats.sumCustomers.repurchaseBuyers) {
            data.push({
                label: 'Repurchase Buyers',
                value:  Utils.formatIntNumber(customerStats.sumCustomers.repurchaseBuyers || 0),
                tooltip: 'Total Number of Buyers Who Have Made a Previous Order'
            });
        }
        return data;
    }

    render() {
        const {
            loadingCustomerStats,
            merchantId,
            startTime,
            endTime,
            customerStats,
        } = this.state || {};

        let dynamicContent;
        if(loadingCustomerStats) {
            dynamicContent = (
                <Row gutter={8} type="flex" justify="center" key="spin">
                    <Spin size="large" />
                </Row>
            );
        } 
        
        else {
            const cardsData = this.convertCustomerStatsForCards(customerStats);
            const cards = cardsData.map( d=> {
                return (
                    <Col xs={12} sm={8} md={8} lg={6} xl={6} key={d.label}>
                        <StatsCard data={d} />
                    </Col>
                )
            });
        
            const  visitorsGraph = (customerStats ? <VisitorsLineGraph chartData={customerStats.dailyCustomers} 
                startTime={startTime} endTime={endTime} /> : null)
            
            const usersPieChart = (customerStats ? <UserCompPieChart chartData={customerStats.sumCustomers} /> : null)

            const usersBarGraph = (customerStats ? <UserCompBarGraph chartData = {customerStats.dailyCustomers} 
                startTime={startTime} endTime={endTime} /> : null)

            const usersConversionFunnel = ((customerStats ? <UserConversionFunnel chartData = {customerStats.conversionFunnel} /> : null))
            dynamicContent = (
                <React.Fragment>
                    <Row gutter={8} type="flex" justify="center" key="cards">
                        {cards}
                    </Row>
                    <br />
                    <Row gutter={8} type="flex" justify="center" key="visitors">
                        <Col xs={18} sm={18} md={12} lg={12} xl={12}>
                        { visitorsGraph }
                        </Col>
                    </Row>
                    <br />
                    <Row gutter={8} type="flex" justify="center" key="user_comp">
                        <Col xs={18} sm={18} md={18} lg={10} xl={10}>
                            { usersPieChart }
                        </Col>
                        <Col xs={18} sm={18} md={18} lg={10} xl={10}>
                            { usersBarGraph }
                        </Col>
                    </Row>
                    <br />
                    <Row gutter={8} type="flex" justify="center" key="user_funnel">
                        <Col xs={18} sm={18} md={18} lg={10} xl={10}>
                            { usersConversionFunnel }
                        </Col>
                    </Row>
                </React.Fragment>
            )
        }
        const content = (
            <div style={{ padding: '24px 0',background: '#fff', textAlign: 'center' }}>
                <FilterPickerRow startTime={startTime} 
                    endTime={endTime} 
                    merchantId={merchantId}
                    onDateRangeChanged={this.handleDateRangeChanged}
                    onMerchantChanged={this.handleMerchantSelectorChanged}
                />
                <br />
                { dynamicContent }
            </div>
        )

        return (
            <BasicLayout content = {content}/>
        )
    }
}

export default CustomersView;
