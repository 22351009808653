import React, {Component} from 'react';
import {
    Col,
    Row,
    Spin,
} from 'antd';
import {toast} from 'react-toastify';

import BasicLayout from '../common/BasicLayout';
import FilterPickerRow from '../common/FilterPickerRow';
import SummaryDailyChart from './SummaryDailyChart';
import StatsCard from '../common/StatsCard';
import SummaryProductsTable from './SummaryProductsTable';
import SummaryBrandsTable from './SummaryBrandsTable';
import MerchantAPI from '../../utils/MerchantAPI';
import FifthAveAPI from '../../utils/FifthAveAPI';
import AccountManager from '../../utils/AccountManager';
import FilterInfoManager from '../../utils/FilterInfoManager';
import {VIEW_MODE} from '../../utils/Consts';
import Utils from '../../utils/Utils';

class SummaryView extends Component {
    constructor(props) {
        super(props);
        const cachedFilter = FilterInfoManager.loadCachedFilter();
        this.state = {
            loadingSummary: false,
            endTime: cachedFilter.endTime,
            startTime: cachedFilter.startTime,
            merchantId: cachedFilter.merchantId || null,
            productsMap: {},
        }
    }

    componentDidMount() {
        AccountManager.updateAccountInfo();
        this.loadSummary();
    }

    loadSummary = () => {
        let {merchantId, startTime, endTime} = this.state || {};
        const {mode} = this.props;
        if (!merchantId && mode === VIEW_MODE.ADMIN) {
            this.setState({
                loadingSummary: false,
            });
            return;
        }
        this.setState({
            loadingSummary: true,
        });
        // we need to convert the date range to UTC time, since our data is in UTC time
        // get the timezoneOffset from UTC in milliseconds
        const timezoneOffset = new Date().getTimezoneOffset() * 60 * 1000;
        startTime -= timezoneOffset;
        endTime -= timezoneOffset;

        if (mode === VIEW_MODE.ADMIN) {
            MerchantAPI.getAdminSummary({merchantId, startTime, endTime}).then((response) => {
                this.setState({
                    loadingSummary: false,
                    summary: response.data,
                }, () => {
                    this.loadDetailInfoForProducts();
                });
            })
                .catch((error) => {
                    console.error(error);
                    this.setState({
                        loadingSummary: false,
                    });
                    toast.error('Load summary failed. ' + error.toString());
                });
        } else {
            MerchantAPI.getSummary({startTime, endTime}).then((response) => {
                this.setState({
                    loadingSummary: false,
                    summary: response.data,
                }, () => {
                    this.loadDetailInfoForProducts();
                });
            })
                .catch((error) => {
                    console.error(error);
                    this.setState({
                        loadingSummary: false,
                    });
                    toast.error('Load summary failed. ' + error.toString());
                });
        }
    }

    loadDetailInfoForProducts = () => {
        const {summary} = this.state || {};
        if (!summary || !summary.products || summary.products.length === 0) {
            // if products are invalid, then do nothing
            return;
        }
        const productIds = summary.products.map(d => d.productId);
        this.setState({
            loadingSummary: true,
        })
        FifthAveAPI.batchLoadProducts(productIds).then((response) => {
            const {productsMap} = this.state || {};
            if (response.data) {
                response.data.forEach((p) => {
                    productsMap[p.id] = p;
                });
            }

            this.setState({
                productsMap: productsMap,
                loadingSummary: false,
            });
        })
            .catch((error) => {
                console.error(error);
                this.setState({
                    loadingSummary: false,
                });
            })
    }

    onInputChanged = (e) => {
        const {name, value} = e.target
        this.setState({[name]: value});
    }

    handleMerchantSelectorChanged = (merchantId) => {
        this.setState({
            merchantId
        }, () => {
            this.loadSummary();
        });
    }

    handleDateRangeChanged = (dataRange) => {
        const {startTime, endTime} = dataRange || {};
        this.setState({
            startTime,
            endTime,
        }, () => {
            this.loadSummary();
        })
    }

    convertSummaryTotalForUI = (summary) => {
        let data = [];
        if (!summary || !summary.total) {
            return data;
        }

        if (summary.total.salesCents) {
            data.push({
                label: 'Sales',
                value: Utils.formatCurrencyNumber(summary.total.salesCents / 100.0),
                tooltip: 'Amount of the Sales in $USD.',
            });
        }

        if (summary.total.ordersNumber) {
            data.push({
                label: 'Orders',
                value: Utils.formatIntNumber(summary.total.ordersNumber),
                tooltip: 'Number of the Order Placed.',
            });
        }
        if (summary.total.customersNumber) {
            data.push({
                label: 'Customers',
                value: Utils.formatIntNumber(summary.total.customersNumber),
                tooltip: 'Number of Customers.',
            });
        }

        if (summary.total.aovCents) {
            data.push({
                label: 'Average Order Value',
                value: Utils.formatCurrencyNumber(summary.total.aovCents / 100.0),
                tooltip: 'Average Order Value.',
            });
        }

        if (summary.total.upt) {
            data.push({
                label: 'Units Per Transaction',
                value: Utils.formatFloatNumber(summary.total.upt),
                tooltip: 'Units Per Transaction',
            });
        }

        return data;
    }

    renderContent = () => {
        const {
            loadingSummary,
            summary,
            merchantId,
            startTime,
            endTime,
            productsMap,
        } = this.state || {};

        let dynamicContent;
        if (loadingSummary) {
            dynamicContent = (
                <Row gutter={8} type="flex" justify="center" key="spin">
                    <Spin size="large"/>
                </Row>
            );
        } else {
            const cardsData = this.convertSummaryTotalForUI(summary);
            const cards = cardsData.map(d => {
                return (
                    <Col xs={20} sm={10} md={10} lg={4} xl={4} key={d.label}>
                        <StatsCard data={d}/>
                    </Col>
                )
            });

            const dailyChart = (summary ? (<SummaryDailyChart chartData={summary.dailySummaries} startTime={startTime} endTime={endTime}/>) : null);

            const productsTable = (summary ? <SummaryProductsTable data={summary.products || []} productsMap={productsMap} merchantId={merchantId}/> : null);

            const brandsTable = (summary ? <SummaryBrandsTable data={summary.brands || []}/> : null);

            dynamicContent = (
                <React.Fragment>
                    <Row gutter={8} type="flex" justify="center" key="cards">
                        {cards}
                    </Row>
                    <br/>
                    <Row gutter={8} type="flex" justify="center" key="dailyChart">
                        <Col xs={18} sm={18} md={18} lg={18} xl={18}>
                            {dailyChart}
                        </Col>
                    </Row>
                    <br/>
                    <Row gutter={8} type="flex" justify="center" key="topSaleProducts">
                        <Col xs={18} sm={18} md={18} lg={18} xl={18}>
                            {productsTable}
                        </Col>
                    </Row>
                    <br/>
                    <Row gutter={8} type="flex" justify="center" key="topSaleBrands">
                        <Col xs={18} sm={18} md={18} lg={18} xl={18}>
                            {brandsTable}
                        </Col>
                    </Row>
                </React.Fragment>
            )
        }

        const content = (
            <div style={{padding: '24px 0', background: '#fff', textAlign: 'center'}}>
                <FilterPickerRow startTime={startTime}
                                 endTime={endTime}
                                 merchantId={merchantId}
                                 onDateRangeChanged={this.handleDateRangeChanged}
                                 onMerchantChanged={this.handleMerchantSelectorChanged}
                />
                <br/>
                {dynamicContent}
            </div>
        )
        return content;
    }

    render() {

        const content = this.renderContent();

        return (
            <BasicLayout content={content}/>
        );
    }
}

export default SummaryView;
