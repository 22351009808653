import CacheDataManager from './CacheDataManager';
import moment from 'moment';

class FilterInfoManager {
  static updateFilterCache(updateInfo){
    // load filter from cache
    let filter = CacheDataManager.loadFilter();
    // update the key and value
    Object.keys(updateInfo).forEach(key=> {
      filter[key] = updateInfo[key];
    })
    // save to local storage
    CacheDataManager.saveFilter(filter);
  }

  static loadCachedFilter() {
    let filter = CacheDataManager.loadFilter();
    let needUpdate = false;
    if (!filter.startTime) {
      // add the default startTime
      filter.startTime = moment().startOf('day').subtract(7, 'days').valueOf();
      needUpdate = true;
    }

    if (!filter.endTime) {
      // add the default endTime
      filter.endTime = moment().endOf('day').subtract(1, 'days').valueOf();
      needUpdate = true;
    }

    if (needUpdate) {
      // update if necessary
      CacheDataManager.saveFilter(filter);
    }

    return filter;
  }
}

export default FilterInfoManager;