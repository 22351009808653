export const APP_ENV_TYPE = {
  PRODUCTION: "production",
  DEVELOP: "develop"
};

export const GATEWAY_CONFIG = {
  PATH : {
    HOME: '/',
    LOGIN: '/login',
    SUMMARY: '/summary',
    ADMIN_SUMMARY: '/admin/summary',
    ORDERS: '/orders',
    ADMIN_ORDERS: '/admin/orders',
    CUSTOMERS: '/customers',
    ADMIN_CUSTOMERS: '/admin/customers',
  }
};

export const VIEW_MODE = {
  ADMIN: 'ADMIN',
  MERCHANT: 'MERCHANT',
};

export const CHART_DATE_DEFAULT_FORMAT = 'MM/DD';
export const CHART_DATE_FULL_FORMAT = 'YYYY/MM/DD ddd';
